<template>
    <div>

        <v-layout align-center justify-center row fill-height  column class="search_component" style="height:1000px">

            <div style="height:100%">

                <v-container>
                <v-layout row wrap  >

              <v-flex xs3></v-flex>

            <v-flex xs6>
            

                <v-img src="/assets/logo_black.png" style="      height: 100%;
    width: 100%;"></v-img>



            
            </v-flex>
              
             
 <v-flex xs3></v-flex>

            </v-layout>



                     <v-row style="padding-top: 40px;position: relative;
    ">
                   
                 

                        <v-alert :value="true" type="success" style="font-weight:bold;font-size:23px">
                            شكرا لنضمماكم لمنصه احجز الي الحساب غير مفعل سوف يتم تفعيله قريبا
                        </v-alert>

                        
                    </v-row>



                    <v-layout row wrap  >

              <v-flex xs3></v-flex>

            <v-flex xs6>
            

               <v-btn  class="" style="color:#fff;font-weight:bold;font-size:23px; "  color="red" width="100%" @click="logout()" >خروج</v-btn>


            
            </v-flex>
              
             
 <v-flex xs3></v-flex>

            </v-layout>

                </v-container>

            </div>
        </v-layout>


    </div>
</template>



<script>

  export default {
 
    name: 'app',
    data: () => ({
    

    }),

    mounted() {
     
    },


    methods: {
        logout()
        {
            this.$router.push({
          name: "LoginOwner"
        });
        }



    },
    components: {

    }
  }
</script>